import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import PreSubscribe from "./components/PreSubscribe";
import Marketplace from "./components/Marketplace";
// import Profile from "./components/Profile";
// import VideoPage from "./components/VideoPage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Web3 from "web3";
// import WalletConnectProvider from "@walletconnect/web3-provider";
import { useState } from "react";
import useCryptoUser from "./components/hooks/useCryptoUser";
// import Constants from "./constants/Constants";
import TermsOfService from "./components/TermsOfService";
// import Stake from "./components/Stake";
import Faq from "./components/FAQ";
import { LanguageProvider } from "./context/LanguageContext";
// const wcProvider = new WalletConnectProvider({
//   rpc: {
//     97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
//     56: "https://bsc-dataseed.binance.org/",
//   },
// });

function App() {
  const { cryptoUser, setWalletType, setCryptoUser, logoutUser } =
    useCryptoUser();
  const [provider, setProvider] = useState(window.ethereum);

  // const connect = async (walletProvider) => {
  //   if (walletProvider === "Metamask") {
  //     setWalletType("METAMASK");
  //     let metamaskProvider = window.ethereum;
  //     if (typeof metamaskProvider !== "undefined") {
  //       const id = toast.loading("Connecting...");
  //       setProvider(metamaskProvider);
  //       metamaskProvider
  //         .request({ method: "eth_requestAccounts" })
  //         .then(async (accounts) => {
  //           let res = await fetch("/v1/api/user/create", {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //             body: JSON.stringify({
  //               walletId: accounts,
  //             }),
  //           });
  //           res = await res.json();
  //           console.log(res);
  //           if (res.status === 200 || res.status === 409) {
  //             toast.success("Wallet connected");
  //             setCryptoUser(res.data);
  //           } else {
  //             toast.error("1 " + res.status);
  //           }
  //           toast.dismiss(id);
  //         })
  //         .catch((err) => {
  //           toast.dismiss(id);
  //           toast.error("Failed to connect wallet");
  //           toast.error(err);
  //         });
  //     } else {
  //       console.log(Constants);
  //       window.open(Constants.DEEP_LINK);
  //     }
  //   } else if (walletProvider === "WalletConnect") {
  //     setWalletType("WALLETCONNECT");
  //     await wcProvider.enable();
  //     const web3 = new Web3(wcProvider);
  //     setProvider(wcProvider);
  //     const accounts = await web3.eth.getAccounts();
  //     if (Array.isArray(accounts) && accounts.length !== 0) {
  //       const id = toast.loading("Connecting...");
  //       let res = await fetch("/v1/api/user/create", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           walletId: accounts,
  //         }),
  //       });
  //       res = await res.json();
  //       if (res.status === 200 || res.status === 409) {
  //         toast.success("Wallet connected");
  //         setCryptoUser(res.data);
  //       } else {
  //         toast.error("Failed to connect wallet");
  //       }
  //       toast.dismiss(id);
  //     } else {
  //       toast.error("Failed to connect wallet");
  //     }
  //   }
  // };

  // const logout = async () => {
  //   if (provider && typeof provider.disconnect === "function")
  //     await provider.disconnect();
  //   removeWalletConnectFromLocalStorage();
  //   toast.success("Wallet disconnected");
  //   logoutUser();
  // };

  // const removeWalletConnectFromLocalStorage = () => {
  //   localStorage.removeItem("walletconnect");
  //   localStorage.removeItem("WALLETCONNECT_DEEPLINK_CHOICE");
  //   localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
  // };

  return (
    <LanguageProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />

          {/* <Route
            path="home"
            element={
              <Marketplace
                connect={connect}
                logout={logout}
                cryptoUser={cryptoUser}
                provider={provider}
              />
            }
          /> */}
          {/* <Route
            path="video/:id"
            element={
              <VideoPage
                connect={connect}
                logout={logout}
                cryptoUser={cryptoUser}
                provider={provider}
              />
            }
          />
          <Route
            path="profile"
            element={
              <Profile
                connect={connect}
                logout={logout}
                cryptoUser={cryptoUser}
                provider={provider}
              />
            }
          /> */}
          <Route
            path="privacy"
            element={
              <PrivacyPolicy
              // connect={connect}
              // logout={logout}
              // cryptoUser={cryptoUser}
              // provider={provider}
              />
            }
          />
          {/* <Route
            path="terms-of-service"
            element={
              <TermsOfService
                connect={connect}
                logout={logout}
                cryptoUser={cryptoUser}
                provider={provider}
              />
            }
          /> */}
          {/* <Route
            path="governance"
            element={
              <Stake
                connect={connect}
                logout={logout}
                cryptoUser={cryptoUser}
                provider={provider}
              />
            }
          /> */}
          <Route
            path="faq"
            element={
              <Faq
              />
            }
          />
        </Routes>
        <ToastContainer theme="dark" />
      </BrowserRouter>
    </LanguageProvider>
  );
}

export default App;

import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import Footer from './Footer'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Faq from "react-faq-component";

function FAQ({ }) {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleOpen = () => setShow(true);
    const handleClose = () => setShow(false);

    const data = {
        rows: [
            {
                title: <h2 style={{ color: 'white', fontSize: '18px' }}><img src="/logo.png" style={{ width: '30px' }} /> What You Need to Know</h2>,
                content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat,
                  ultricies metus at, consequat velit. Curabitur est nibh, varius in tellus nec, mattis pulvinar metus.
                  In maximus cursus lorem, nec laoreet velit eleifend vel. Ut aliquet mauris tortor, sed egestas libero interdum vitae.
                  Fusce sed commodo purus, at tempus turpis.`,
            },
            {
                title: <h2 style={{ color: 'white', fontSize: '18px' }}><img src="/logo.png" style={{ width: '30px' }} /> How do I find my funds on Polygon?</h2>,
                content:
                    "Nunc maximus, magna at ultricies elementum, risus turpis vulputate quam, vitae convallis ex tortor sed dolor.",
            },
            {
                title: <h2 style={{ color: 'white', fontSize: '18px' }}><img src="/logo.png" style={{ width: '30px' }} /> Where can I find NFT sales?</h2>,
                content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
                Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
                Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
                Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
            },
            {
                title: <h2 style={{ color: 'white', fontSize: '18px' }}><img src="/logo.png" style={{ width: '30px' }} /> How can I export my NFT sales on OpenSea for taxes?</h2>,
                content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
                Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
                Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
                Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
            },
            {
                title: <h2 style={{ color: 'white', fontSize: '18px' }}><img src="/logo.png" style={{ width: '30px' }} /> How can I connect my social account with my collection?</h2>,
                content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
                Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
                Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
                Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
            },
            {
                title: <h2 style={{ color: 'white', fontSize: '18px' }}><img src="/logo.png" style={{ width: '30px' }} /> How do I find my transaction hash?</h2>,
                content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
                Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
                Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
                Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
            },
            {
                title: <h2 style={{ color: 'white', fontSize: '18px' }}><img src="/logo.png" style={{ width: '30px' }} /> What is a floor price?</h2>,
                content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
                Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
                Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
                Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
            },
            {
                title: <h2 style={{ color: 'white', fontSize: '18px' }}><img src="/logo.png" style={{ width: '30px' }} /> What are gas fees on Ethereum?</h2>,
                content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
                Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
                Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
                Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
            },
            {
                title: <h2 style={{ color: 'white', fontSize: '18px' }}><img src="/logo.png" style={{ width: '30px' }} /> How do I transfer ETH from Ethereum to Polygon?</h2>,
                content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
                Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
                Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
                Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
            },
            {
                title: <h2 style={{ color: 'white', fontSize: '18px' }}><img src="/logo.png" style={{ width: '30px' }} /> How do I withdraw funds from Polygon?</h2>,
                content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
                Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
                Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
                Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
            },
            {
                title: <h2 style={{ color: 'white', fontSize: '18px' }}><img src="/logo.png" style={{ width: '30px' }} /> How can I switch my wallet to blockchains like Polygon?</h2>,
                content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
                Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
                Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
                Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
            },
            {
                title: <h2 style={{ color: 'white', fontSize: '18px' }}><img src="/logo.png" style={{ width: '30px' }} /> What's WETH? How do I get it?</h2>,
                content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
                Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
                Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
                Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
            },
            {
                title: <h2 style={{ color: 'white', fontSize: '18px' }}><img src="/logo.png" style={{ width: '30px' }} /> How can I contact OpenSea?</h2>,
                content: `Curabitur laoreet, mauris vel blandit fringilla, leo elit rhoncus nunc, ac sagittis leo elit vel lorem.
                Fusce tempor lacus ut libero posuere viverra. Nunc velit dolor, tincidunt at varius vel, laoreet vel quam.
                Sed dolor urna, lobortis in arcu auctor, tincidunt mattis ante. Vivamus venenatis ultricies nibh in volutpat.
                Cras eu metus quis leo vestibulum feugiat nec sagittis lacus.Mauris vulputate arcu sed massa euismod dignissim. `,
            }
        ],
    };

    const styles = {
        bgColor: 'transparent',
        rowTitleColor: "white",
        rowContentColor: 'white',
        arrowColor: "white",
    };

    const config = {
        // animate: true,
        // arrowIcon: "V",
        // tabFocus: true
    };

    return <div className="bg-black" style={{ color: 'white', background: 'rgb(0,0,0)' }}>
        <Navbar collapseOnSelect expand="lg" bg="black" variant="dark">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        alt="RedEye Logo"
                        src="/assets/images/nav-red-eye.svg"
                        height="40"
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Link href="/">Home</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link href="/home">Marketplace</Nav.Link>
                    </Nav>
                    {/* <Nav>
    {cryptoUser && <Nav.Link href="/profile">My Profile</Nav.Link> }
  </Nav>
      <Nav>
    {!cryptoUser ? <Button onClick={handleOpen} className="mx-3 btn btn-danger">Connect Wallet</Button> :<Button onClick={() => {logout(); navigate('/');}} className="mx-3 btn btn-danger">Logout</Button> }
    </Nav> */}
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <Container>
            {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Connect Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div onClick={() => {connect('Metamask');handleClose();}} style={{cursor:'pointer', width: '200px', border: '1px solid #fff', borderRadius: '10px', padding: '20px 30px 0', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <img src="/MetaMask_Fox.png" style={{width: '100%'}} />
                <p style={{fontWeight: 'bolder'}}>Metamask</p>
            </div>
            <div onClick={() => {connect('WalletConnect');handleClose()}} className="mt-2" style={{cursor:'pointer', width: '200px', border: '1px solid #fff', borderRadius: '10px', padding: '20px 30px 0', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <img src="/wallet_connect.ico" style={{width: '100%'}} />
                <p style={{fontWeight: 'bolder'}}>Wallet Connect</p>
            </div>
        </Modal.Body>
      </Modal> */}

            <div className="my-5">
                <h1 style={{ color: '#71768E', fontSize: '24px' }}><Link to="/home" style={{ color: '#71768E', textDecoration: 'none' }}>Home</Link> {'>'} <span style={{ color: '#FF454A' }}>Frequently Asked Questions</span></h1>
                <p className="mt-4">Frequently Asked Questions</p>
                <p>Answers to all the questions you have!</p>
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>

        </Container>
        <Footer />
    </div>;
}

export default FAQ;
import {Container, Row, Col, Button,FloatingLabel, Form} from 'react-bootstrap';
import { Link } from "react-router-dom";
import LanguageSwitcher from './LanguageSwitcher';
import { useLanguage } from "../context/LanguageContext";

function Footer() {
  const { getTranslation } = useLanguage();

    return <div id="contact" className="container-fluid footer-box py-5 px-5" style={{backgroundColor: '#000'}}>
    <div className="first-footer flex-row-sb py-3">
      <Row style={{width: '100%'}}>
        <Col>
        <div className="policy">
        <div className="logo-container mb-3">
          <img src="/assets/images/red-eye-white.svg" alt="logo" style={{width:'250px', height: 'auto'}} />
        </div>
        <div className="policy-links">
          <Link style={{color: '#ABABAB'}} to="/privacy">{getTranslation('nav_privacy_policy')}</Link>
          <Link style={{color: '#ABABAB'}} to="/terms-of-service">{getTranslation('nav_terms_of_service')}</Link>
        </div>
      </div>
        </Col>
        <Col>
        <Row className="footer-links">
        <Col sm={4}>
        <div style={{display: 'flex', flexDirection: 'column', minWidth: '150px'}}>
          <a style={{color: '#fff', textDecoration: 'none'}} className="mb-3" href="/"> {getTranslation('nav_presentation')} </a>
         <a style={{color: '#fff', textDecoration: 'none'}} className="mb-3" href="/"> {getTranslation('nav_problems')} </a>
         <a style={{color: '#fff', textDecoration: 'none'}} className="mb-3" href="/"> {getTranslation('nav_solution')} </a>
        </div>
        </Col>
        <Col sm={4}>
        <div style={{display: 'flex', flexDirection: 'column', minWidth: '150px'}}>
         <a style={{color: '#fff', textDecoration: 'none'}} className="mb-3" href="/whitepaper.pdf"> {getTranslation('nav_white_paper')} </a>
         <a style={{color: '#fff', textDecoration: 'none'}} className="mb-3" href="/"> {getTranslation('nav_pre_subs')} </a>
         <a style={{color: '#fff', textDecoration: 'none'}} className="mb-3" href="/"> {getTranslation('nav_distribution')} </a>
        </div>
        </Col>
        <Col sm={4}>
        <div style={{display: 'flex', flexDirection: 'column', minWidth: '150px'}}>
         <p style={{color: '#fff', textDecoration: 'none'}} className="mb-3"> {getTranslation('nav_contact')} <br/>
         <a href="mailto:redeye.business@redeyed.io">redeye.business@redeyed.io</a></p>
        </div>
        </Col>
        </Row>
        </Col>
        </Row>
     
      
    </div>
    <div className="second-footer py-3">
      <div className="copyright" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <p style={{color: '#ABABAB'}}>{getTranslation('home_copyright_text')}</p>
        <div>
        <LanguageSwitcher />
        </div>
      </div>
      <div className="social-links flex-css">
        <a href="https://twitter.com/officialredeye1" className="flex-css">
          <i style={{color: '#ABABAB'}} className="fab fa-twitter"></i>
        </a>
        <a href="https://www.instagram.com/officialredeyed/" className="flex-css">
          <i style={{color: '#ABABAB'}} className="fab fa-instagram"></i>
        </a>
        <a href="https://discord.com/channels/551915364389486622/551915364389486624" className="flex-css">
          <i style={{color: '#ABABAB'}} className="fab fa-discord"></i>
        </a>
        <a href="https://www.youtube.com/channel/UCv8XMecsPSEyYkFLec2tMyA/videos" className="flex-css">
          <i style={{color: '#ABABAB'}} className="fab fa-youtube"></i>
        </a>
        <a href="https://t.me/redEyedOfficial" className="flex-css">
          <i style={{color: '#ABABAB'}} className="fab fa-telegram"></i>
        </a>
      </div>
    </div>
  </div>;
}

export default Footer;
import { useState } from "react";

export default function useCryptoUser() {
  const getCryptoUser = () => {
    try {
      const user = localStorage.getItem("crypto_user");
      if (user && user !== "null")
        return JSON.parse(user).walletId.toLowerCase();
      return null;
    } catch {
      return null;
    }
  };

  const getUserId = () => {
    try {
      const user = localStorage.getItem("crypto_user");
      if (user && user !== "null") return JSON.parse(user).id;
      return null;
    } catch {
      return null;
    }
  };

  const getWalletType = () => {
    try {
      const wallet_type = localStorage.getItem("wallet_type");
      if (typeof wallet_type === "string") return wallet_type;
      return null;
    } catch {
      return null;
    }
  };

  const [cryptoUser, setCryptoUser] = useState(getCryptoUser());
  const [userId, setUserId] = useState(getUserId());
  const [walletType, setWalletType] = useState(getWalletType());

  const saveCryptoUser = (user) => {
    localStorage.setItem("crypto_user", JSON.stringify(user));
    setCryptoUser(user.walletId.toLowerCase());
    setUserId(user.id);
  };

  const saveWalletType = (wallet_type) => {
    localStorage.setItem("wallet_type", wallet_type);
    setWalletType(wallet_type);
  };

  const deleteCryptoUser = () => {
    localStorage.removeItem("crypto_user");
    localStorage.removeItem("wallet_type");
    setWalletType(null);
    setCryptoUser(null);
  };

  return {
    setCryptoUser: saveCryptoUser,
    setWalletType: saveWalletType,
    cryptoUser,
    userId,
    walletType,
    logoutUser: deleteCryptoUser,
  };
}

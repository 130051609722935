import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useLanguage } from "../context/LanguageContext";

function NavigationBar() {
  const { getTranslation } = useLanguage();

  return <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: '#0B0A0A' }} variant="dark">
    <Container>
      <Navbar.Brand href="/">
        <img
          alt="RedEye Logo"
          src="/assets/images/nav-red-eye.svg"
          height="40"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
        <Nav>
          <Nav.Link href="#">{getTranslation('nav_presentation')}</Nav.Link>
          <Nav.Link href="#problem">{getTranslation('nav_problems')}</Nav.Link>
          <Nav.Link href="#solution">{getTranslation('nav_solution')}</Nav.Link>
          <Nav.Link href="https://redeyed.io/whitepaper.pdf" target="_blank">{getTranslation('nav_white_paper')}</Nav.Link>
          <Nav.Link href="/faq">FAQs</Nav.Link>
          <Nav.Link href="#distribution">{getTranslation('nav_distributions')}</Nav.Link>
          <Link to="https://redeyeddev.manaknightdigital.com/client/login"><Button style={{ marginLeft: '10px' }} className="red-btn">Launch App</Button></Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>;
}

export default NavigationBar;
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import Footer from './Footer'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";

function PrivacyPolicy({ cryptoUser }) {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleOpen = () => setShow(true);
    const handleClose = () => setShow(false);

    return <div className="bg-black" style={{
        color: 'white', background: 'rgb(0,0,0)',
        background: 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(78,30,30,1) 50%, rgba(0,0,0,1) 100%)'
    }}>
        <Navbar collapseOnSelect expand="lg" bg="black" variant="dark">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        alt="RedEye Logo"
                        src="/assets/images/nav-red-eye.svg"
                        height="40"
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Link href="/">Home</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link href="/home">Marketplace</Nav.Link>
                    </Nav>
                    {/* <Nav>
    {cryptoUser && <Nav.Link href="/profile">My Profile</Nav.Link> }
  </Nav> */}
                    {/* <Nav>
    {!cryptoUser ? <Button onClick={handleOpen} className="mx-3 btn btn-danger">Connect Wallet</Button> :<Button onClick={() => {logout(); navigate('/');}} className="mx-3 btn btn-danger">Logout</Button> }
    </Nav> */}
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <Container>
            {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Connect Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div onClick={() => {connect('Metamask');handleClose();}} style={{cursor:'pointer', width: '200px', border: '1px solid #fff', borderRadius: '10px', padding: '20px 30px 0', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <img src="/MetaMask_Fox.png" style={{width: '100%'}} />
                <p style={{fontWeight: 'bolder'}}>Metamask</p>
            </div>
            <div onClick={() => {connect('WalletConnect');handleClose()}} className="mt-2" style={{cursor:'pointer', width: '200px', border: '1px solid #fff', borderRadius: '10px', padding: '20px 30px 0', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <img src="/wallet_connect.ico" style={{width: '100%'}} />
                <p style={{fontWeight: 'bolder'}}>Wallet Connect</p>
            </div>
        </Modal.Body>
      </Modal> */}
            <div className="m-5" style={{ textAlign: 'justify' }}>
                <h1 className="header-text-font">Privacy Policy</h1>
                Privacy Policy
                Last Updated: March 1, 2022<br /><br />
                Impulse Media, SRL. Trusted custodians of RedEyed (“RedEyed,”
                “we”, “us”, or “our”) is committed to protecting your privacy. We have
                prepared this Privacy Policy to describe to you our practices regarding
                the Personal Data (as defined below) we collect, use, and share in
                connection with the RedEyed website, mobile app, and other software
                provided on or in connection with our services, as described in our
                Terms of Service (collectively, the “Service”). “NFT” in this Privacy
                Policy means a non-fungible token or similar digital item implemented
                on a blockchain (such as the Binance Smartchain blockchain), which
                uses smart contracts to link to or otherwise be associated with certain
                content or data.<br /><br />
                <ol>
                    <li>
                        Types of Data We Collect. <b>“Personal Data”</b> means data that
                        allows someone to identify you individually, including, for
                        example, your name, email address, as well as any other nonpublic information about you that is associated with or linked to
                        any of the foregoing. <b>“Anonymous Data”</b> means data, including
                        aggregated and de-identified data, that is not associated with or
                        linked to your Personal Data; Anonymous Data does not, by
                        itself, permit the identification of individual persons.
                    </li>
                    <li><b>WE DO NOT COLLECT PERSONAL DATA</b> we only collect
                        Anonymous Data as described below.
                        <ol type="a">
                            <li>
                                Information You Provide Us.
                                <ol type="I">
                                    <li>When you use our Service, update your account
                                        profile, or contact us, <b>WE DO NOT COLLECT</b>
                                        Personal Data from you, such as email address, first
                                        and last name, user name, and other information. We
                                        only collect your blockchain address, which is not
                                        associated with any Personal Data when you use our
                                        Service.</li>
                                    <li>Our Service lets you store preferences like the region
                                        your content is displayed, a preferred user name,
                                        notification settings, and favorites. We only associate
                                        these choices with your wallet ID.</li>
                                    <li>We do not ask for feedback or contact us, we will not
                                        collect your name and contact information, as well as
                                        any other content included in any message you
                                        manage to send us.</li>
                                    <li>If at any time we require personal data for our
                                        operation we will clearly state so.</li>
                                </ol>
                            </li>
                            <li>Information Collected via Technology. As you navigate through and interact with our Service, we will attempt to not
                                collect any anonymous data except what is absolutely
                                necessary for the operation of the service, we may use
                                automatic data collection technologies to collect certain
                                information about your equipment, browsing actions, and
                                patterns, including:
                                <ol type="I">
                                    <li>Information Collected by Our Servers. To provide our
                                        Service and make it more useful to you, we (or a third
                                        party service provider) collect information from you,
                                        including, but not limited to, your browser type,
                                        operating system, Internet Protocol (“IP”) address,
                                        mobile device ID, blockchain address, wallet type,
                                        and date/time stamps. NONE OF THIS CAN BE
                                        LINKED BACK TO PERSONAL DATA.</li>
                                    <li>Log Files. We will not user Log Files to personalize
                                        your experience, if we must use them it will be only in
                                        a manner necessary for the operation of the service.</li>
                                    <li>Cookies. We will not use cookies to personalize your
                                        experience, if we do use of them it will only be for the
                                        absolute operation of the platform.</li>
                                    <li>Pixel Tag. In addition, we use “Pixel Tags” (also
                                        referred to as clear Gifs, Web beacons, or Web bugs).
                                        Pixel Tags allow us to analyze how users find our
                                        Service, this will be used by third party for the
                                        purpose of placing Ads in some of our channels, ADS
                                        are regional based and regions as declared by the
                                        video/stream location and not any identifiable user
                                        provided info.</li>
                                    <li>How We Respond to Do Not Track Signals. Our
                                        systems do not currently recognize “do not track”
                                        signals or other mechanisms that might enable Users
                                        to opt out of tracking on our site.</li>
                                    <li>Analytics Services. In addition to the tracking
                                        technologies we place like Cookies and Pixel Tags,
                                        other companies may set their own cookies or similar
                                        tools when you visit our Service. This includes thirdparty analytics services (“Analytics Services”) that we
                                        engage to help analyze how users use the Service.
                                        The information generated by the Cookies or other
                                        technologies about your use of our Service (the
                                        “Analytics Information”) is transmitted to the Analytics
                                        Services. The Analytics Services use Analytics
                                        Information to compile reports on user activity, which
                                        we may receive on an individual or aggregate basis.
                                        We use the information we get from Analytics
                                        Services to improve our Service. The Analytics
                                        Services may also transfer information to third parties
                                        where required to do so by law, or where such third
                                        parties process Analytics Information on their behalf.
                                        Each Analytics Services’ ability to use and share
                                        Analytics Information is restricted by such Analytics
                                        Services’ terms of use and privacy policy. By using
                                        our Service, you consent to the processing of data
                                        about you by Analytics Services in the manner and for
                                        the purposes set out above.</li>
                                </ol>
                            </li>
                            <li>Information Collected from Third-Party Companies. We
                                may receive Personal and/or Anonymous Data about you
                                from companies that offer their products and/or services for
                                use in conjunction with our Service or whose products and/
                                or services may be linked from our Service. For example,
                                third-party wallet providers provide us with your blockchain
                                address and certain other information you choose to share
                                with those wallets providers. WE WILL NOT STORE
                                PERSONAL DATA PROVIDED BY ANY THIRD PARTY.</li>
                            <li>Public Information Observed from Blockchains. We collect
                                data from activity that is publicly visible and/or accessible
                                on blockchains. This may include blockchain addresses
                                and information regarding purchases, sales, or transfers of
                                NFTs, which will only be associated with your Wallet ID.</li>
                        </ol>

                    </li>
                    <li>Use of Your anonymous Data.
                        <ol type="a">
                            <li>We process your Data to run our business, provide the
                                Service, personalize your experience on the Service, and
                                improve the Service. Specifically, we use your Data to:
                                <ol type="I">
                                    <li>facilitate the creation of and secure your account;</li>
                                    <li>identify you as a user in our system;</li>
                                    <li>provide you with our Service, including, but not limited
                                        to, helping you view, explore, and create NFTs using
                                        our tools and, at your own discretion, connect directly
                                        with others to purchase, sell, or transfer NFTs on
                                        public blockchains;</li>
                                    <li>improve the administration of our Service and quality
                                        of experience when you interact with our Service,
                                        including, but not limited to, by analyzing how you and
                                        other users find and interact with the Service;</li>
                                    <li>provide customer support and respond to your
                                        requests and inquiries;</li>
                                    <li>investigate and address conduct that may violate our
                                        Terms of Service;</li>
                                    <li>detect, prevent, and address fraud, violations of our
                                        terms or policies, and/or other harmful or unlawful
                                        activity; </li>
                                    <li>display your username next to the NFTs currently or
                                        previously accessible in your third-party wallet, and
                                        next to NFTs on which you have interacted;</li>
                                    <li>send you in APP notifications related to actions on the
                                        Service, including notifications of offers on your NFTs;</li>
                                    <li>comply with applicable laws, cooperate with
                                        investigations by law enforcement or other authorities
                                        of suspected violations of law, and/or to pursue or
                                        defend against legal threats and/or claims; and</li>
                                    <li>act in any other way we may describe when you
                                        provide the Data.</li>
                                </ol>
                            </li>
                            <li>We use Anonymous Data to analyze request and usage
                                patterns so that we may improve our Services and enhance
                                Service navigation. We reserve the right to use Anonymous
                                Data for any purpose and to disclose Anonymous Data to
                                third parties without restriction.</li>
                        </ol>
                    </li>
                    <li>Disclosure of Your Personal Data. WE DO NOT COLLECT ANY.</li>
                    <li>Third-Party Websites. Our Service may contain links to thirdparty websites. When you click on a link to any other website or
                        location, you will leave our Service and go to another site, and
                        another entity may collect Personal Data from you. We have no
                        control over, do not review, and cannot be responsible for these
                        third-party websites or their content. Please be aware that the
                        terms of this Privacy Policy do not apply to these third-party
                        websites or their content, or to any collection of your Personal
                        Data after you click on links to such third-party websites. We
                        encourage you to read the privacy policies of every website you
                        visit. Any links to third-party websites or locations are for your
                        convenience and do not signify our endorsement of such third
                        parties or their products, content, or websites.</li>
                    <li>Third-Party Wallets. To use our Service, you must use a thirdparty wallet which allows you to engage in transactions on public
                        blockchains. Your interactions with any third-party wallet provider
                        are governed by the applicable terms of service and privacy
                        policy of that third party.</li>
                    <li>Your Choices Regarding Information. You have several choices
                        regarding the use of information on our Services:
                        <ol type="a">
                            <li>Email Communications. We will not send you any email
                                communications as we do not collect or require an email to
                                operate.</li>
                            <li>If you decide at any time that you no longer wish to accept
                                Cookies from our Service for any of the purposes described
                                above, then you can instruct your browser, by changing its
                                settings, to stop accepting Cookies or to prompt you before
                                accepting a Cookie from the websites you visit. Consult
                                your browser’s technical information. If you do not accept
                                Cookies, however, you may not be able to use all portions
                                of the Service or all functionality of the Service.</li>
                        </ol>
                    </li>
                    <li>Data Access and Control.
                        <ol type="a">
                            <li>If you have any questions or concerns regarding our
                                Access to your data please reach out to one of our social
                                media communities.</li>
                            <li>Notwithstanding the above, we cannot edit or delete any
                                information that is stored on a blockchain, for example the
                                Binance Smart Chain blockchain, as we do not have
                                custody or control over any blockchains. The information
                                stored on the blockchain may include purchases, sales,
                                and transfers related to your blockchain address and NFTs
                                held at that address.</li>
                        </ol>
                    </li>
                    <li>Data Retention. We may retain your anonymous Data as long as
                        you continue to use the Service, have an account with us, or for
                        as long as is necessary to fulfill the purposes outlined in this
                        Privacy Policy. We may continue to retain your Personal Data
                        even after you deactivate your account and/or cease to use the
                        Service if such retention is reasonably necessary to comply with
                        our legal obligations, to resolve disputes, prevent fraud and
                        abuse, enforce our Terms or other agreements, and/or protect
                        our legitimate interests. Where your Personal Data is no longer
                        required for these purposes, we will delete it.</li>
                    <li>Data Protection. We do not store any keys, seed phrases or vital
                        personal information. Your keys your NTFs. In the event of a
                        breach of your personal wallet keys we are in no way
                        responsible for any losses you may suffer. We will never ask for
                        your keys or pass, or seed phrases.</li>
                    <li>Minors. We do not intentionally gather Personal Data from
                        visitors who are under the age of 13. Our Terms of Service
                        require all users to be at least 18 years old. Minors who are at
                        least 13 years old but are under 18 years old may use a parent
                        or guardian’s RedEyed account, but only with the involvement of
                        the account holder.</li>
                    <li>Users inside the United States. If you are a U.S. user of the
                        Service, by visiting the Service and providing us with data, you
                        acknowledge and agree that your anonymous Data may be
                        processed for the purposes identified in the Privacy Policy. In
                        addition, your Data may be processed in the country in which it
                        was collected and in other countries, including the United States,
                        where laws regarding processing of Personal Data may be less
                        stringent than the laws in your country. By providing your
                        anonymous Data, you consent to such transfer.</li>
                    <li>Changes to This Privacy Policy. This Privacy Policy may be
                        updated from time to time for any reason. We will notify you of
                        any changes to our Privacy Policy by posting the new Privacy
                        Policy at <Link to="/privacy">https://redeyed.io/privacy</Link>. The date the Privacy Policy
                        was last revised is identified at the beginning of this Privacy
                        Policy. You are responsible for periodically visiting our Service
                        and this Privacy Policy to check for any changes.</li>
                    <li>Questions; Contacting RedEyed; Reporting Violations. If you
                        have any questions or concerns or complaints about our Privacy
                        Policy or our data collection or processing practices, or if you
                        want to report any security violations to us, please contact us by
                        visiting one of our online communities and letting the community
                        assist you.</li>
                </ol>

            </div>


        </Container>
        <Footer />
    </div>;
}

export default PrivacyPolicy;
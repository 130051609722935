import { createContext, useContext, useEffect, useState } from 'react'
import {translations} from './Translations';

const LanguageContext = createContext(undefined)

export function LanguageProvider({ children }) {

    const localSetting = localStorage.getItem('kraftLanguage');
    let currentLanguage = 'en';

    if (localSetting && localSetting === 'en') {
      currentLanguage = 'en'
    }
    else if (localSetting && localSetting === 'esp') {
      currentLanguage = 'esp'
    }

  const [language, setLanguage] = useState(currentLanguage);

  const getTranslation = (key) => {
    return translations[language][key];
  }

  useEffect(() => {
    if (language !== localStorage.getItem('kraftLanguage')) {
      localStorage.setItem('kraftLanguage', language)
    }
  }, [language])

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage,
        getTranslation
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

export function useLanguage() {
  const context = useContext(LanguageContext)

  if (!context)
    throw new Error('useLanguage must be used inside a `LanguageProvider`')

  return context
}